<template>
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div
      v-if="file"
      class="content-wrapper"
    >
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">
                {{ file.name_cat }} <small>{{ file.name_en }}</small><br>
                <!-- <small class="me-2 font-weight-bold">{{ file.departments }}</small> -->
                <small>{{ file.section ? file.section.name : '' }} {{ file.subsection ? '/ ' + file.subsection.name : '' }}</small>
                <br>
                <small v-if="institution"><span class="badge bg-light-primary dis-ib">{{ institution.name }}</span></small> <!-- solo admin -->
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <span
              class="dis-ib ms-50"
            >
              <a
                class="btn btn-icon btn-secondary"
                @click="window.history.length > 1 ? $router.go(-1) : $router.push({name:'cae.view', params: {id: file.section.id}})"
              >
                <i data-feather="chevron-left" />
              </a>
            </span>
            <span
              class="dis-ib ms-50"
              title="Search documents"
              @click="openFilters"
            >
              <a
                class="btn btn-icon btn-primary"
              ><i data-feather="search" /></a>
            </span>

            <span
              v-if="loggedUser.roles.includes('super-admin') || loggedUser.roles.includes('cae')"
              class="dis-ib ms-50"
            >
              <a>
                <b-dropdown
                  toggle-class="p-75"
                  right
                >
                  <template #button-content>
                    <i
                      class="white"
                      data-feather="more-vertical"
                    />
                  </template>
                  <div
                    class="dropup dropdown-icon-wrapper d-none d-sm-block"
                  >
                    <b-dropdown-item
                      :href="$router.resolve({name: 'cae.labour-hazards.edit', params: file.id, query: { actAs: $route.query.actAs }}).href"
                      target="_blank"
                    >
                      <i
                        class="me-50"
                        data-feather="edit-3"
                      /> Edit document
                    </b-dropdown-item>
                    <b-dropdown-item>
                      <i
                        class="me-50"
                        style="color: red;"
                        data-feather="trash-2"
                      /> <span style="color: red;">Delete document</span>
                    </b-dropdown-item>
                    <template v-if="loggedUser.roles.includes('super-admin')">
                      <hr>
                      <b-dropdown-item @click="acceptDocument(true)">
                        <i
                          class="me-50"
                          style="color: limegreen;"
                          data-feather="check"
                        /> <span style="color: limegreen;">Accept document</span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="acceptDocument(false)">
                        <i
                          class="me-50"
                          data-feather="x"
                          style="color: red;"
                        /> <span style="color: red;">Reject document</span>
                      </b-dropdown-item>
                    </template>
                  </div>
                </b-dropdown>
              </a>
            </span>
            <!-- <span
              class="dis-ib ms-50"
            >
              <a
                class="btn btn-icon btn-primary"
              ><i data-feather="search" /></a>
            </span> -->
          </div>
        </div>
      </div>
      <hr>
      <div class="content-body content-body--senior-call">

        <p class="mb-2">
          Updated: <strong>{{ file.uploaded }}</strong>
        </p>

        <div class="row">
          <div class="col-12">
            <div class="row">
              <div
                v-if="file.description_en || file.description_cat || loggedUser.roles.includes('super-admin') || loggedUser.roles.includes('cae')"
                class="col-sm-8"
              >
                <div
                  v-if="file.description_en && file.description_en.length > 0"
                  class="card"
                >
                  <div class="card-body ">
                    <h4>Description <span class="badge bg-light-secondary">EN</span></h4>
                    <p v-html="file.description_en" />
                  </div>
                </div>
                <div
                  v-if="file.description_cat && file.description_cat.length > 0 && loggedUser.roles.includes('cae')"
                  class="card"
                >
                  <div class="card-body ">
                    <h4>Description <span class="badge bg-light-secondary">CAT</span></h4>
                    <p v-html="file.description_cat" />
                  </div>
                </div>

                <DocumentIcreas v-if="loggedUser.roles.includes('super-admin') || loggedUser.roles.includes('cae')" />
              </div>
              <div class="col-sm-4">
                <DocumentFeatures />
                <NeedFeedback />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Search />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import Vue from 'vue'
import NeedFeedback from './partials/NeedFeedback.vue'
import DocumentFeatures from './partials/DocumentFeatures.vue'
import DocumentIcreas from './partials/DocumentIcreas.vue'
import Search from './partials/Search.vue'

export default {
  components: {
    NeedFeedback,
    DocumentFeatures,
    DocumentIcreas,
    BDropdown,
    Search,
    BDropdownItem,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      file: 'cae/file',
      filters: 'filters/filters',
      loggedUser: 'auth/admin',
      institution: 'cae/institution',
    }),
  },
  async mounted() {
    let institution = null
    if (!this.institution) {
      institution = { id: this.$route.query.host }
    } else {
      institution = this.institution
    }

    if (!institution) {
      this.$router.push({ name: 'cae.index' })
    }

    this.$store.dispatch('filters/saveFilters', {
      value: institution,
      field: 'host',
    })

    await this.$store.dispatch('cae/fetchFile', { filters: this.filters, id: this.$route.params.id })

    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'archived_files',
      })
    }

    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 100)
  },
  methods: {
    openFilters() {
      this.$store.dispatch('modals/toggleConvoFilters', true)
    },
    async showDeleteModal(id) {
      await this.$store.dispatch('modals/saveDeleteData', { name: 'disseminationTypes', url: `${Vue.prototype.$groupUrl}/activities/dissemination-types/${id}` })
      this.$store.dispatch('modals/showDeleteModal', true)
    },
    async acceptDocument(accept) {
      Vue.swal({
        title: `Do you want to ${accept ? 'accept' : 'reject'} this document?`,
        html: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Yes',
      }).then(async result => {
        if (result.isConfirmed) {
          await this.$store.dispatch('cae/acceptDocument', { file: this.file.id, accept, host: this.filters.host })
          Vue.swal('Document status changed successfully', '', 'success')
        }
      })
    },
  },
}
</script>
